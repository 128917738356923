import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { ApiService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-add-adhoc-vehicle',
  templateUrl: './add-adhoc-vehicle.component.html',
  styleUrls: ['./add-adhoc-vehicle.component.scss']
})
export class AddAdhocVehicleComponent {

  _mVehicleTypes: any[] = [];
  _mDriversData: any[] = [];
  registrationNo: any = '';
  vehicleType: any = '';
  mapToExistingDriver: boolean = false;
  driverName: any = '';
  licenseNumber: any = '';
  contactNumber: any = '';
  mVendorMasters: any = '';
  _mVMData: any = [];
  remark: any = '';

  constructor(
    public dialogRef: MatDialogRef<AddAdhocVehicleComponent>,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ngxLoader: NgxUiLoaderService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.getVehicleTypes();
    this.getAllVendor();
  }

  getVehicleTypes() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getAllVehicleType, success => {
      if (success.status == 200) {
        this._mVehicleTypes = success.body.dataList.content;
        this._mVehicleTypes = this._mVehicleTypes.filter(
          vehicletype => vehicletype.status == AppConstants.ACTIVE_STATUS
        );
      }
    }, error => {
      console.log(error);
    });
  }

  discard_special_char_And_String(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || (k === 32));
  }


  discard_special_char(event) {
    var k;
    k = event.charCode;
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

  onStatusChange() {
    this.driverName = '';
    this.mVendorMasters = '';
    this._mDriversData = [];
    this.licenseNumber = '';
    this.contactNumber = '';
    this.remark = '';
  }

  omit_special_char_And_num(event) {
    let k;
    k = event.charCode;
    return ((k >= 48 && k <= 57));
  }

  getAllVendor() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getAllVendorDropDownBasedOnSite + this.data,
      success => {
        if (success != null) {
          this._mVMData = success.body.filter(
            ele => ele.status == AppConstants.ACTIVE_STATUS
          );
        } else {
          this._mVMData = [];
          this.notifyService.showInfo("No Vendors master data found", null);
        }
      },
      error => {
        this.notifyService.showError(error.message, null);
      }
    );
  }

  getDriverDetails(event) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getByvendor + event.target.value, (success) => {
      if (success.status == 200) {
        this._mDriversData = success.body.filter(
          driver => driver.status == AppConstants.ACTIVE_STATUS
        );
      } else {
        this._mDriversData = [];
        this.notifyService.showWarning("No Drivers Found", null);
      }
    },
      error => {
        this.notifyService.showError(error.message, null);
      }
    );
  }

  onSelectDriver(event) {
    let findObj = this._mDriversData.find(ele => ele.id == event.target.value);
    this.licenseNumber = findObj.licenseNumber;
    this.contactNumber = findObj.contactNumber;
  }

  onConfirmation() {
    let sendData: any = '';
    if (this.mapToExistingDriver) {
      sendData = {
        "vehicleTypeId": this.vehicleType,
        "registrationNo": this.registrationNo,
        "siteId": this.data,
        "vendorMasterId": this.mVendorMasters,
        "driverId": this.driverName,
        "mapToExistingDriver": this.mapToExistingDriver,
        "remark": this.remark
      }
    } else {
      sendData = {
        "vehicleTypeId": this.vehicleType,
        "registrationNo": this.registrationNo,
        "siteId": this.data,
        "vendorMasterId": this.mVendorMasters,
        "driverName": this.driverName,
        "licenseNumber": this.licenseNumber,
        "contactNumber": this.contactNumber,
        "mapToExistingDriver": this.mapToExistingDriver,
        "remark": this.remark
      }
    }
    if (sendData) {
      this.ngxLoader.start();
      this.apiService.doPostRequestWithResponse(ApiConstants.addOutSourceVehicle, sendData, (success) => {
        this.ngxLoader.stop();
        if (success.status == 200) {
          if (success.body.message == 'Added successfully') {
            this.dialogRef.close("");
            this.notifyService.showSuccess(success.body.message, null);
          } else {
            this.notifyService.showWarning(success.body.message, null);
          }
        }
      }, (error) => {
        this.ngxLoader.stop();
        this.notifyService.showError("Failed to add", null);
      });
    }
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }
}
