import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TripWarningComponent } from './trip-warning/trip-warning.component';
import { MatTableDataSource } from '@angular/material/table';
import { AuditLogComponent } from 'src/app/shared/audit-log/audit-log/audit-log.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-adjustment-trips',
  templateUrl: './adjustment-trips.component.html',
  styleUrls: ['./adjustment-trips.component.scss']
})
export class AdjustmentTripsComponent {

  mData: any[] = [];
  isAdjustmentTrips: boolean = true;
  _mCurrentUserData: any;
  _mEmpId: number = null;
  _mCurrentUserRoleFlag: boolean = false;
  page: number = 0;
  size: number = 20;
  _mCurrentNumber: number = 0;
  _mTotalElements: number = 0;
  _mNumberOfElements: number = 0;
  _mTotalPage: number = 1;
  dataSource = new MatTableDataSource<any>(this.mData);
  displayedColumns = ['date', 'tripCode', 'siteName', 'vendorName', 'employeeId', 'logType', 'nearestShiftTime', 'approximateKm', 'vehicleType', 'driverName', 'raisedOn', 'actionOn', 'actionBy', 'status', 'audit'];
  activeTabId = 1;
  statusData = [{ status: 'ALL' },{ status: 'SENT_FOR_ADMIN_APPROVAL' }, { status: 'Approved' }, { status: 'Rejected' }]
  cities: any;
  _mSitesData: any;
  public _mFormGroup: FormGroup;
  public mLocation = new FormControl("", Validators.required);
  public mSite = new FormControl("", Validators.required);
  public mFromDate = new FormControl('');
  public mToDate = new FormControl("", Validators.required);
  public mstatus = new FormControl("", Validators.required);
  status = '';
  minDate: any;
  maxDate: any;
  tableFlag: boolean;
  itemsPerPage = 0;

  constructor(
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    public readonly serviceLocalStorage: ServiceLocalStorage,
    private ngxLoader: NgxUiLoaderService,
    public dialog: MatDialog,
    private fb: FormBuilder
  ) {
    this._mFormGroup = this.fb.group({
      location: this.mLocation,
      site: this.mSite,
      fromDate : this.mFromDate,
      toDate : this.mToDate,
      status: this.mstatus
    });
  }

  ngOnInit() {
    this.getCurrentUser();
    this.loadCities();
  }

  getCurrentUser() {
    this._mCurrentUserData = this.serviceLocalStorage.getUserObject();
    if (this._mCurrentUserData != null) {
      if (this._mCurrentUserData.userInfoDetails.role.id == 3 || this._mCurrentUserData.userInfoDetails.role.id == 4) 
        {
        this._mCurrentUserRoleFlag = true;
      }
      this._mEmpId = this._mCurrentUserData.userInfoDetails.role.id == 1 || this._mCurrentUserData.userInfoDetails.role.id == 2 ||
       this._mCurrentUserData.userInfoDetails.role.id == 4
          ? ""
          : this._mCurrentUserData.userInfoDetails.id;
    }
  }

  pendingStatusRequest(showmsg, i) {
    if (
      showmsg == "SENT_FOR_VENDOR_APPROVAL" ||
      showmsg == "CANCELLED_BY_ADMIN" ||
      showmsg == "REJECTED_BY_ADMIN"
    ) {
      let msg = null;
      if (showmsg == "REJECTED_BY_ADMIN") {
        msg = "Are you sure want to Reject ?";
      } else if (showmsg == "CANCELLED_BY_ADMIN") {
        msg = "Are you sure want to Cancel ?";
      } else {
        msg = "Are you sure want to Approve ?";
      }

      const data = msg;
      const dialogRef = this.dialog.open(TripWarningComponent, {
        data: msg,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.onSubmitStatus(showmsg, i);
        }
      });
    }
  }

  onSubmitStatus(showmsg, index) {
    let data = this.mData[index];
    data.status = showmsg;
    if (data.status == 'SENT_FOR_VENDOR_APPROVAL') {
      data.status = 'Approved';
    }
    this.ngxLoader.start();
    this.apiService.doPutRequestWithResponse(ApiConstants.updateAdjustmentTrip + data.id + '&status=' + data.status, '', (success) => {
      this.ngxLoader.stop();
      if (success.status === 200) {
        this.notifyService.showSuccess("Booking Updated Successfully.", null);
      }
      else {
        this.ngxLoader.stop();
        this.notifyService.showWarning("Error while Booking update", null);
      }
    },
      error => {
        this.ngxLoader.stop();
      }
    );

  }

  onAuditLogClicked(e) {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getAdjustmentTripAudit + e.id, (success) => {
      this.ngxLoader.stop();
      if (success.status == 200) {
        let data = success.body;
        let object = Object.entries(e);
        data.data.push(object[2]);
        this.dialog.open(AuditLogComponent, {
          autoFocus: false,
          maxHeight: '80vh',
          data: data,
          disableClose: true
        });
      } else {
        this.notifyService.showInfo("No audit logs found", null);
      }
    }, (error) => {
      this.ngxLoader.stop();
      this.notifyService.showWarning("No audit logs found.", null);
    });
  }

  loadCities() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getserchlocationlist,
      success => {
        if (success.status == 200) {
          this.cities = success.body;
        } else {
          this.cities = [];
        }
      },
      error => {
        this.notifyService.showError(error, null);
      }
    );
  }

  dateChanged(data) {
    this.mToDate.setValue("");
    this.minDate = data.value;
  }
  getSitesByCityId() {
    this.mSite.setValue('');
    this.apiService.doGetRequestWithResponse(ApiConstants.getserchsitelist +
      this.mLocation.value,
      success => {
        if (success.status == 200) {
          this._mSitesData = success.body;
        } else {
          this._mSitesData = [];
          this.notifyService.showInfo("No Sites Found!", null);
        }
      },
      error => {
      }
    );
  }

  onSubmit()
  {
    if(this._mFormGroup.valid)
    {
    this.ngxLoader.start();
    this.apiService.doGetRequestWithResponse(ApiConstants.getAdjustmentTrip + this.mSite.value + '&fromDate='+ this.formatDate(this.mFromDate.value) + '&toDate='+ this.formatDate(this.mToDate.value) +
      '&status=' + this.mstatus.value + '&page=' + (this.page - 1) + '&size=' + this.size, (success) => {
      if (success.status == 200) {
        this.ngxLoader.stop();
        this.tableFlag = true;
        if(success.body.data.length == 0)
        {
           this.notifyService.showInfo("No data found", null);
           this.mData = [];
           this._mCurrentNumber = 0;
           this._mTotalElements = 0;
           this._mTotalPage = 0;
           this._mNumberOfElements = 0;
           this.size = 0;
        }
        else
        {
          let locarr: any[] = [];
          success.body.data.forEach(element => {
            locarr.push({
              "id": element.id, "date": element.date, "tripCode": element.tripCode, "siteName": element.siteName, "vendorName": element.vendorName,
              "employeeId": element.employeeId, "logType": element.logType, "nearestShiftTime": element.nearestShiftTime, "approximateKm": element.approximateKm, "status": element.status, "vehicleType": element.vehicleType, "driverName": element.driverName, "raisedOn": element.raisedOn, "actionOn": element.actionOn, "actionBy": element.actionBy
            });
          });
          this.mData = locarr;
          this.dataSource.data = locarr;
          this._mCurrentNumber = success.body.pageNo;
          this._mTotalPage = success.body.totalPages;
          this.size = success.body.size;
          this._mTotalElements = success.body.totalElements;
          this._mNumberOfElements = success.body.noOfElements;
        }
      }
      else {
        this.ngxLoader.stop();
        this.mData = [];
        this._mCurrentNumber = 0;
        this._mTotalElements = 0;
        this._mTotalPage = 0;
        this._mNumberOfElements = 0;
        this.size = 0;
      }
    },
      (error) => {
        this.notifyService.showError("error", null);
      })
  }
}

formatDate(d): any {
  let m = d.getMonth() + 1;
  let dt = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  let mm = m < 10 ? "0" + m : m;
  return d.getFullYear() + "-" + mm + "-" + dt;
}

onNewPageRequest($event) {
  if($event !== null || $event !== undefined)
  {
  this.page = $event;
  this.onSubmit();
  }
}

}




