<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <ul ngbNav #nav="ngbNav" [activeId]="activeTabId" class="nav-tabs nav-tabs-custom g-nav-tabs-custom"
                style="padding-top: 7px;">
                <li [ngbNavItem]="1">
                    <a ngbNavLink> Adjustment Trips</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="p-3"></div>
            <div class="card-body">
                <div class="row" style="margin: 0px;">
                    <div class="col-sm-12 p-0" [formGroup]="_mFormGroup">
                        <div class="row mt-2">
                            <div class="col-sm-2">
                                <label>Location<sup class="mandatory">*</sup></label>
                                <select class="form-select margin-right-8px" placeholder="Location" formControlName="location" (change)="getSitesByCityId()"  required>
                                    <option disabled value="">Choose city</option>
                                    <option *ngFor="let city of cities" [value]="city.locationId">
                                        {{ city.locationName }}
                                    </option>
                                </select>
                            </div>
                    
                            <div class="col-sm-2">
                                <label>Site<sup class="mandatory">*</sup></label>
                                <select class="form-select" placeholder="Site" formControlName="site" required>
                                    <option value="" disabled selected>Select site</option>
                                    <option *ngFor="let site of _mSitesData" [value]="site.siteId">
                                        {{ site.siteName }}
                                    </option>
                                </select>
                            </div>
                    
                            <div class="col-sm-2">
                                <label>From date <sup class="mandatory">*</sup></label>
                                <mat-form-field appearance="outline" class="adjustement-trips">
                                    <input matInput [matDatepicker]="picker" (click)="picker.open()" (dateChange)="dateChanged($event)"
                                        readonly placeholder="From date" formControlName="fromDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                        <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                    
                            <div class="col-sm-2">
                                <label>To date <sup class="mandatory">*</sup></label>
                                <mat-form-field appearance="outline" class="adjustement-trips">
                                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker1" (click)="picker1.open()"
                                        readonly placeholder="To date" formControlName="toDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker1">
                                        <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                            </div>
                    
                            <div class="col-sm-2">
                                <label>Status<sup class="mandatory">*</sup></label>
                                <select class="form-select" placeholder="Status" formControlName="status" required>
                                    <option value="" disabled selected>Select Status</option>
                                    <option *ngFor="let status of statusData" [value]="status.status">
                                        {{ status.status }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-sm-2">
                                <button class="btn red-btn-solid" style="margin: 30px;" (click)="onSubmit()"
                                [disabled]="!_mFormGroup.valid">
                                    Submit
                                </button>
                            </div>
                        </div>
                    
                    </div>
                </div>
                
                <div *ngIf="tableFlag && mData.length > 0">
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="float-end font-size-13" *ngIf="_mTotalElements > 0">
                                {{ _mCurrentNumber * size + 1 }} - {{ _mCurrentNumber * size +
                                _mNumberOfElements }} of {{ _mTotalElements }}
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="table-responsive">
                            <table mat-table [dataSource]="dataSource" class="table table-borderless customtripsTable" style=" overflow-x: auto;">
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef> Date </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.date}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="tripCode">
                                    <th mat-header-cell *matHeaderCellDef>Trip Code</th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.tripCode}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="siteName">
                                    <th mat-header-cell *matHeaderCellDef> Site </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.siteName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="vendorName">
                                    <th mat-header-cell *matHeaderCellDef> Requested By </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.vendorName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="employeeId">
                                    <th mat-header-cell *matHeaderCellDef> Employee Id </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.employeeId}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="logType">
                                    <th mat-header-cell *matHeaderCellDef> Log Type </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.logType}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="nearestShiftTime">
                                    <th mat-header-cell *matHeaderCellDef> Nearest ShiftTime </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.nearestShiftTime}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="approximateKm">
                                    <th mat-header-cell *matHeaderCellDef> Approximate KM </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.approximateKm}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="vehicleType">
                                    <th mat-header-cell *matHeaderCellDef> Vehicle Type </th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.vehicleType}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="driverName">
                                    <th mat-header-cell *matHeaderCellDef> Driver Name</th>
                                    <td mat-cell *matCellDef="let element"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.driverName}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="raisedOn">
                                    <th mat-header-cell *matHeaderCellDef> Raised On </th>
                                    <td mat-cell *matCellDef="let element"> {{element.raisedOn}} </td>
                                </ng-container>

                                <ng-container matColumnDef="actionOn">
                                    <th mat-header-cell *matHeaderCellDef> Action On </th>
                                    <td mat-cell *matCellDef="let element"> {{element.actionOn}} </td>
                                </ng-container>

                                <ng-container matColumnDef="actionBy">
                                    <th mat-header-cell *matHeaderCellDef> Action By </th>
                                    <td mat-cell *matCellDef="let element"> {{element.actionBy}} </td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Status </th>
                                    <td mat-cell *matCellDef="let element;let i = index;"
                                        style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        <button
                                            *ngIf="element.status =='SENT_FOR_ADMIN_APPROVAL' && !_mCurrentUserRoleFlag"
                                            (click)="pendingStatusRequest('SENT_FOR_VENDOR_APPROVAL', i)"
                                            class="btn button-approve">Approve</button>
                                        <button
                                            *ngIf="element.status =='SENT_FOR_ADMIN_APPROVAL' && !_mCurrentUserRoleFlag"
                                            (click)="pendingStatusRequest('REJECTED_BY_ADMIN', i)"
                                            class="btn button-reject">Reject</button>
                                        <div style="display: flex;">
                                            {{element.status}}
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="audit">
                                    <th mat-header-cell *matHeaderCellDef> Audit </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="list-inline-item">
                                            <a href="javascript:void(0);" title="Audit"
                                                (click)="onAuditLogClicked(element)">
                                                <img src="assets/images/icons/audit.svg" alt="Audit Icon">
                                            </a>
                                        </span>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                    </div>
                        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="_mTotalElements"
                        [pageSize]="size" [(page)]="page" (pageChange)="onNewPageRequest($event)"
                        [maxSize]="5" [boundaryLinks]="true">
                    </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div class="row mt-3" *ngIf="mData.length == 0 && tableFlag">
    <div class="col-sm-12 col-md-12">
        <h4 class="tdw-text-align-center tdw-font-normal tdw-margin-4
          mat-color-text--blue-grey-600">
            --No Data Found --</h4>
    </div>
</div>