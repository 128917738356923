<div class="row">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex add-div" style="justify-content: space-between;">
                    <div style=" font-family: 'poppins'; font-weight: 500; font-size: 14px;">
                        Vendor Adjustment Trips 
                      </div>
                    <button type="button" class="btn btn-outline1" (click)="openDialog(null)">
                        <em class="bx bx-plus me-1"></em>
                        Vendor Adjustment Trips
                    </button>
                </div>

                <div class="row mt-5" style="margin: 0;">
                    <div class="col-sm-12 p-0" [formGroup]="_mFormGroup">
                        <div class="row mt-2">
                            <div class="col-sm-2">
                                <label>Location<sup class="mandatory">*</sup></label>
                                <select class="form-select margin-right-8px" placeholder="Location" formControlName="location" (change)="getSitesByCityId()"  required>
                                    <option disabled value="">Choose city</option>
                                    <option *ngFor="let city of cities" [value]="city.locationId">
                                        {{ city.locationName }}
                                    </option>
                                </select>
                            </div>
                    
                            <div class="col-sm-2">
                                <label>Site<sup class="mandatory">*</sup></label>
                                <select class="form-select" placeholder="Site" formControlName="site" required>
                                    <option value="" disabled selected>Select site</option>
                                    <option *ngFor="let site of _mSitesData" [value]="site.siteId">
                                        {{ site.siteName }}
                                    </option>
                                </select>
                            </div>
                    
                            <div class="col-sm-2">
                                <label>From date <sup class="mandatory">*</sup></label>
                                <mat-form-field appearance="outline" class="vendorAdjustement-trips">
                                    <input matInput [matDatepicker]="picker" (click)="picker.open()" (dateChange)="dateChanged($event)"
                                        readonly placeholder="From date" formControlName="fromDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                        <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                    
                            <div class="col-sm-2">
                                <label>To date <sup class="mandatory">*</sup></label>
                                <mat-form-field appearance="outline" class="vendorAdjustement-trips">
                                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker1" (click)="picker1.open()"
                                        readonly placeholder="To date" formControlName="toDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker1">
                                        <img matDatepickerToggleIcon src="assets/images/bx-calendar.svg">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                            </div>
                    
                            <div class="col-sm-2">
                                <label>Status<sup class="mandatory">*</sup></label>
                                <select class="form-select" placeholder="Status" formControlName="status" required>
                                    <option value="" disabled selected>Select Status</option>
                                    <option *ngFor="let status of statusData" [value]="status.status">
                                        {{ status.status }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-sm-2">
                                <button class="btn red-btn-solid" style="margin: 30px;" (click)="onSubmit()"
                                [disabled]="!_mFormGroup.valid">
                                    Submit
                                </button>
                            </div>
                        </div>
                    
                    </div>
                </div>
                <div *ngIf="tableFlag && _mData.length > 0">
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div class="float-end font-size-13" *ngIf="_mTotalElements > 0">
                                {{ _mCurrentNumber * size + 1 }} - {{ _mCurrentNumber * size +
                                _mNumberOfElements }} of {{ _mTotalElements }}
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="table-responsive">
                            <table mat-table [dataSource]="dataSource" class="table table-borderless customTabletripsvendor">
                                <ng-container matColumnDef="date">
                                    <th mat-header-cell *matHeaderCellDef> Date </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.date}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="tripCode">
                                    <th mat-header-cell *matHeaderCellDef>Trip Code</th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.tripCode}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="siteName">
                                    <th mat-header-cell *matHeaderCellDef> Site Name </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.siteName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="vendorName">
                                    <th mat-header-cell *matHeaderCellDef> Vendor Name </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.vendorName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="employeeId">
                                    <th mat-header-cell *matHeaderCellDef> Employee Id </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.employeeId}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="reason">
                                    <th mat-header-cell *matHeaderCellDef> Reason </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.reason}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="logType">
                                    <th mat-header-cell *matHeaderCellDef> Log Type </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.logType}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="nearestShiftTime">
                                    <th mat-header-cell *matHeaderCellDef> Nearest ShiftTime </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.nearestShiftTime}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="approximateKm">
                                    <th mat-header-cell *matHeaderCellDef> Approximate KM </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.approximateKm}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="escortRequired">
                                    <th mat-header-cell *matHeaderCellDef> Escort Required </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.escortRequired}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="vehicleType">
                                    <th mat-header-cell *matHeaderCellDef> Vehicle Type </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.vehicleType}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="vehicleNo">
                                    <th mat-header-cell *matHeaderCellDef> Vehicle Number </th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.vehicleNo}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="driverName">
                                    <th mat-header-cell *matHeaderCellDef> Driver Name</th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.driverName}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef> Request Status</th>
                                    <td mat-cell *matCellDef="let element" style="font-size: 13px; font-family: poppins; font-weight: 400;">
                                        {{element.status}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                    </div>
                        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="_mTotalElements"
                        [pageSize]="size" [(page)]="page" (pageChange)="onNewPageRequest($event)"
                        [maxSize]="5" [boundaryLinks]="true">
                    </ngb-pagination>
                </div>
            
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row mt-3" *ngIf="_mData.length == 0 && tableFlag">
            <div class="col-sm-12 col-md-12">
                <h4 class="tdw-text-align-center tdw-font-normal tdw-margin-4
                  mat-color-text--blue-grey-600">
                    --No Data Found --</h4>
            </div>
        </div>

               
    <!-- </div>
</div> -->