import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { RouterConstants } from 'src/app/core/constants/router.constants';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { ServiceSharedData } from 'src/app/core/services/shared-data.service';
import { ModelUserInfoDetails } from 'src/app/shared/models/user/user-settings';
import { ConfirmationAlertComponent } from '../confirmation-alert/confirmation-alert.component';
import { Location } from "@angular/common";
import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { debounceTime, distinctUntilChanged, interval, Observable, Subject, Subscription } from 'rxjs';
import { ModelSubscription } from 'src/app/shared/models/subscription';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AppConstants } from 'src/app/core/constants/appconstants';
import { PanicActionNotificationComponent } from '../panic-action-notification/panic-action-notification.component';
import { SearchDialogBoxComponent } from '../context-search/context-search/search-dialog-box/search-dialog-box/search-dialog-box.component';
import { ContextSearchComponent } from '../context-search/context-search/context-search.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WebsocketService } from 'src/app/core/services/websocket.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, AfterViewInit, OnDestroy {

  element;
  userUrl: any = '';
  userName: any = '';
  userId: any;
  imgData: any;
  menuItems = [];
  flagImage: any = false;
  _mPanicActionFlag: boolean = true;
  _mLogo: any;
  username: String;
  companyId: number;
  mapType: any;
  _currentUser: any;
  lastLogin: any;
  _currentUserData: any;
  roleid: number;
  _mRoute: string;
  _mSubRoute: string;
  client_code: string;
  sub: Subscription;
  subs: Subscription;
  panicActionData: any[] = [];
  notificationCount: number = null;
  subscriptionRequests: any = [];
  cancelRequest: ModelSubscription;
  notificationData: any = {};
  private dataToContainer$ = new Subject();
  userInactive: Subject<any> = new Subject();
  routingInactive: Subject<any> = new Subject();
  userActivity;
  routingActivity;
  _rolePermissions: any;
  _mRestrictCovid: any = false;
  _mCheck: any = false;
  atomSession: Subscription;
  settings: boolean;
  configuration: boolean;
  count: any;
  notifications: any;
  notificationFlag: boolean = true;
  searchSubject$ = new Subject<string>();
  _mCurrentUserData: any = this.localStorage.getUserObject();
  id: any = this.localStorage.getUserId();
  empSearchFlag: boolean = false;
  user: any;
  contextSearch: any = "";
  contextSearchEmployeeFlag: boolean = false;
  contextSearchVehicleFlag: boolean = false;
  contextSearchDriverFlag: boolean = false;
  filterValue: any = 'Filter';
  filterFlag: boolean = true;
  employeeFlag: boolean;
  vehicleFlag: boolean;
  driverFlag: boolean;
  public mOptions = [{ value: "Employee" }, { value: "Vehicle" }, { value: "Driver" }];
  _mCurrentNumber: number = 0;
  _mPreviousPageNumber: number = 0;
  _mTotalPage: number = 1;
  _mTotalElements: number = 0;
  _mNumberOfElements: any
  _mSize: number = 0;
  page: number = 1;
  size: number = 10;
  searchDriver: any = '';
  searchVehicle: any = '';
  _mData: any[] = [];
  _mColumns: any[] = [];
  searchSubjectDriver$ = new Subject<string>();
  searchSubjectVehicle$ = new Subject<string>();
  tempSearchKey: any;
  vehicleData: any;
  tempData: any;
  _contextSearch: boolean = false;
  userData: any;
  vendorFlag: boolean = false;
  filterVehicle: boolean = false;
  searchSubjectBasedOnVendor$ = new Subject<string>();
  _mDriversList: any;
  mVendorMasterId: any;
  vendorMasterFlag: boolean = false;
  tempVehicleData: any;
  billingAccess: boolean = false;
  betaIcon: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    private readonly localStorage: ServiceLocalStorage,
    private serviceSharedData: ServiceSharedData,
    private location: Location,
    private sanitizer: DomSanitizer,
    private router: Router,
    public dialog: MatDialog,
    public websocketService: WebsocketService,
    private ngxLoader: NgxUiLoaderService,
  ) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getCurrentUser,
      success => {
        this.empSearchFlag = false;
        this.userData = success.body.userInfoDetails;
        this.billingAccess = success.body.billingAccess;
        if (this.userData.role.name == 'Vendor') {
          this.vendorFlag = true;
          this.vehicleFlag = true;
          this.filterVehicle = true;
          this.mOptions = [{ value: "Vehicle" }, { value: "Driver" }];
          this.getVendorByUserId(success.body.id);
        }
      },
      error => {
        this.empSearchFlag = false;
      }
    );

    this.localStorage.getProfileObs().subscribe((res: any) => {
      this.userUrl = res;
    });

    this.localStorage.getFirstNameObs().subscribe((result: any) => {
      this.userName = result ? result : window.sessionStorage.getItem('userName') !== null ? window.sessionStorage.getItem('userName') : '';
    })

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
      }
    });
    this.searchSubject$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(searchValue => {
      this.empSearchFlag = true;
      if (searchValue.length < 3) {
        this.empSearchFlag = false;
      }
      let spocId = null;
      if (this.userData.role.name === 'SPOC') {
        spocId = this.id;
      } else {
        spocId = 0;
      }
      if (searchValue.length >= 3) {
        this.apiService.doGetRequestWithResponse(ApiConstants.getuserbyfirstname + '?searchkey=' +
          searchValue + '&spocId=' + spocId,
          success => {
            this.empSearchFlag = false;
            this.user = success.body;
          },
          error => {
            this.empSearchFlag = false;
          }
        );
      }
    });
    this.searchSubjectVehicle$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(searchValue => {
      this.empSearchFlag = true;
      if (searchValue.length >= 3) {
        this.apiService.doGetRequestWithResponse(ApiConstants.getVehiclesBasedOnRegistrationNo + '?searchkey=' +
          searchValue,
          success => {
            this.empSearchFlag = false;
            this.vehicleData = success.body;
          },
          error => {
            this.empSearchFlag = false;
          }
        );
      }

    });

  }

  ngOnInit(): void {
    this.element = document.documentElement;
    this.getProfilePhoto();
    if (this.localStorage.hasAuthToken()) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getCurrentUser, success => {
        this._currentUser = success.body;
        this._mLogo = success.body.userInfoDetails.site.location.company;
        this.username = success.body.userInfoDetails.displayName;
        this.mapType = success.body.userInfoDetails.site.location.company.mapType;
        this.companyId = success.body.userInfoDetails.site.location.company.companyId;
        let userInfoDetail: ModelUserInfoDetails = success.body.userInfoDetails;
        this._currentUserData = JSON.stringify(userInfoDetail);
        this.serviceSharedData.setData(ServiceSharedData.CURRENT_USER.company, userInfoDetail.site.location.company);
        this.localStorage.setUserObject(success.body);
        this.localStorage.setRoleId(userInfoDetail.role.id.toString());
        this.localStorage.setRoleName(userInfoDetail.role.name);
        this.localStorage.setLocationId(userInfoDetail.site.location.locationId.toString());
        this.localStorage.setSiteId(userInfoDetail.site.siteId.toString());
        this.localStorage.setCompanyId(userInfoDetail.site.location.company.companyId.toString());
        this.localStorage.setcompanyWeeklyOff(userInfoDetail.site.location.company.weeklyOffDays);
        this.localStorage.setMapType(userInfoDetail.site.location.company.mapType);
        this.localStorage.setUserId(success.body.id.toString());
        this.lastLogin = success.body.lastLoginDate;
        const passwordChangeDate = success.body.pwdChangedDate;
        this._mRoute = this.localStorage.getRouteName();
        this._mSubRoute = this.localStorage.getSubRouteName();
        if (this._mLogo.covidLogo != null) {
          this.flagImage = true;
        }
        this.getDatabaseName(success.body.userInfoDetails.site.location.company.companyId);
        this.getSubscriptionReqCount(success.body.id);
        if (this._currentUser != null) {
          // this.checkPanicAction();
        }
        if (success.body.clientCode == "visa") {
          this.checkUserInactivity();
          this.checkAuthentication();
        }
        this.apiService.doGetRequestWithResponse(ApiConstants.getDelegatedRoleByUserId + success.body.id, success1 => {
          this.roleid = success1.id;
          this.getRolePermission();
        }, error1 => {
          this.roleid = userInfoDetail.role.id;
          this.getRolePermission();
        });
      }, error => {
        console.log(error)
      });
      if (this._mSubRoute == "User") {
        if (this.location.path() != "") {
          let routePath = this.location.path();
          this.updateRouteEnd(routePath);
          return;
        }
      }
    }

    this.getNotificationCount();
    this.websocketService._connect();
    // interval(60000).subscribe(x => {
    //   this.getNotificationCount();
    // });

  }

  getNotificationCount() {
    this.subs = interval(60000).subscribe(val => {
      let userId = this.localStorage.getUserId();
      if (userId) {
        this.apiService.doGetRequestWithResponse(ApiConstants.getInAppNotificationCount, success => {
          if (success.status == 200) {
            this.count = success.body.count;
          }
        }, error => {
          console.log(error);
        })
      }
    });
  }

  getLatestNotifications() {
    let userId = this.localStorage.getUserId();
    if (userId) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getLatestInappNotifications, success => {
        if (success.status == 200) {
          this.notifications = success.body;
        } else {
          this.notifications = [];
          this.notificationFlag = false;
          this.notifyService.showInfo("You don't have any notification", null);
        }
      }, error => {
        console.log(error);
      })
    } else {
      this.notifyService.showInfo("You don't have any notification", null);
    }
  }
  getDatabaseName(companyId) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getDatabaseName + companyId, success => {
      if (success.status == 200) {
        this.client_code = window.btoa(success.body.client);
        this.localStorage.setDatabaseName(success.body.client);
      }
    }, error => {
      console.log(error);
    })
  }

  // checkPanicAction() {
  //   this.sub = interval(60000).subscribe(val => {
  //     if (this._currentUser != null) {
  //       if (this._currentUser.userInfoDetails.role.name == "Super Admin" || this._currentUser.userInfoDetails.role.name == "Admin" ||
  //         this._currentUser.userInfoDetails.role.name == "Vendor" || this._currentUser.userInfoDetails.role.name == "Site Admin") {
  //         if (this._mPanicActionFlag) {
  //           this.apiService.doGetRequestWithResponse(ApiConstants.isPanicActionTakenByAdmin + this._currentUser.userInfoDetails.site.siteId, success => {
  //             if (success.status == 200) {
  //               if (success.body != null) {
  //                 this.panicActionData = [];
  //                 this.panicActionData.push(success.body);
  //                 this.showSingleRunningTrip(success.body.tripId.tripId);
  //               }
  //             }
  //           }, error => {
  //             console.log(error)
  //           });
  //         }
  //       }
  //     }
  //   });
  // }

  // showSingleRunningTrip(tripId) {
  //   let reqData = { tripId: tripId };
  //   this.apiService.doPostRequestWithResponse(ApiConstants.showSingleRunningTrip + reqData.tripId, '', success => {
  //     setTimeout(() => {
  //     if (this._mPanicActionFlag) {
  //       this._mPanicActionFlag = false;
  //       const audio = new Audio("assets/mp3/notification_alarm.mp3");
  //       audio.play();
  //       setTimeout(() => {
  //         audio.pause();
  //       }, 10000);
  //       let reqData = {
  //         tripData: success.body,
  //         panicActionData: this.panicActionData,
  //         currentUserId: this._currentUser.id,
  //         mapType: this.mapType
  //       };
  //       let dialogRef = this.dialog.open(PanicActionNotificationComponent, {
  //         width: "1250px",
  //         data: reqData,
  //         disableClose: true,
  //         panelClass: 'custom-dialog-container'
  //       });
  //       dialogRef.afterClosed().subscribe(result => {
  //         if (result) {
  //           this._mPanicActionFlag = true;
  //           audio.pause();
  //         }
  //       });
  //     } }, 10000);
  //   }, error => {
  //     console.log(error)
  //   });
  // }
 
  getSubscriptionReqCount(userId: number) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getSubscriptionReqCount + userId, success => {
      this.notificationCount = success.body.count;
      this.subscriptionRequests = [];
      if (this.notificationCount > 0) {
        if (userId != undefined) {
          const data = { "userId": userId };
          this.apiService.doPostRequestWithResponse(ApiConstants.isSubscribed, data, subSuccess => {
            if (subSuccess.status == 200) {
              this.cancelRequest = subSuccess.body;
              if ((subSuccess.body.status == "Pending" && subSuccess.body.unsubscriptionEffectiveDate != null) || (subSuccess.body.status == "Pending"
                && subSuccess.body.unsubscriptionEffectiveDate == null)) {
                this.notificationData = {
                  status: "Subscription request",
                  time: subSuccess.body.effectiveFromDate
                };
                this.subscriptionRequests = [];
                this.subscriptionRequests.push(this.notificationData);
              } else if (subSuccess.body.unsubscriptionEffectiveDate != null) {
                this.notificationData = {
                  status: "Unsubscription request",
                  time: subSuccess.body.unsubscriptionEffectiveDate
                };
                this.subscriptionRequests = [];
                this.subscriptionRequests.push(this.notificationData);
              }
            }
          }, error => {
            console.log(error)
          });
        }
      }
    }, error => {
      console.log(error)
    });
    this.dataToContainer$.subscribe(data => {
      this.ngOnInit();
    });
  }

  checkUserInactivity() {
    this.resetUserTimer();
    this.resetRoutingTimer();
    this.userInactive.subscribe(() => {
      if (!this.router.url.includes("/myatom/routing")) {
        if (!this.router.url.includes("/myatom/live-tracking/live/overview")) {
          this.notifyService.showWarning("Idle session timed out", null);
          this.logout();
        }
      }
    });
    this.routingInactive.subscribe(() => {
      if (this.router.url.includes("/myatom/routing")) {
        this.notifyService.showWarning("Idle session timed out", null);
        this.logout();
      }
    });
  }

  resetUserTimer() {
    clearTimeout(this.userActivity);
    this.setUserTimeout();
  }

  setUserTimeout() {
    if (this.localStorage.hasAuthToken()) {
      this.userActivity = setTimeout(() => {
        this.userInactive.next("General");
      }, 900000);
    }
  }

  resetRoutingTimer() {
    clearTimeout(this.routingActivity);
    this.setRoutingTimeout();
  }

  setRoutingTimeout() {
    if (this.localStorage.hasAuthToken()) {
      this.routingActivity = setTimeout(() => {
        this.routingInactive.next("Routing");
      }, 7200000);
    }
  }

  checkAuthentication() {
    if (this.localStorage.hasAuthToken()) {
      this.renewToken();
    }
    this.atomSession = interval(840000).subscribe(val => {
      if (this.localStorage.hasAuthToken()) {
        this.renewToken();
      }
    });
  }

  renewToken() {
    let refreshToken = this.localStorage.getRefToken();
    let userId = this.localStorage.getUserId();
    let clientCode = localStorage.getItem("clientCode")
    if (refreshToken == null || userId == null || clientCode == null) {
      return;
    }
    let data = {
      "refreshToken": refreshToken,
      "userId": userId
    }
    this.apiService.doPostWithResponseRenewJWT(ApiConstants.renewJWT, data, clientCode, success => {
      if (success.status == 200) {
        let bearerToken = success.body.accessToken;
        if (bearerToken !== null && bearerToken.length > 0 && bearerToken.slice(0, AppConstants.BEARER_STRING.length) === AppConstants.BEARER_STRING) {
          this.localStorage.setAuthToken(bearerToken.slice(AppConstants.BEARER_STRING.length, bearerToken.length));
        }
      }
    }, error => {
      this.notifyService.showError("Session expired. Please re-login to continue", null);
      this.localStorage.deleteAll();
      this.router.navigate(['/auth'])
    });
  }

  updateRouteEnd(path: string) {
    if (path.includes("/changepassword")) {
      this._mRoute = "Change Password";
      this._mSubRoute = "";
    }
    else if (path.includes("/user/info")) {
      this._mRoute = "My Profile";
      this._mSubRoute = "";
    } else {
      for (const dashboardItem of this.menuItems) {
        if (dashboardItem.items)
          for (const route of dashboardItem.items) {
            if (path.includes(route.route)) {
              this._mRoute = dashboardItem.name;
              this._mSubRoute = route.name;
              return;
            }
          }
      }
    }
  }

  getRolePermission() {
    // this.getRolePermissions(this.roleid).then(value => {
    this.getRolePermissions(parseInt(this.localStorage.getRoleId())).then(value => {
      this._rolePermissions = this.localStorage.getPermissionObject();
      this.settings = this._rolePermissions.settings;
      this.configuration = this._rolePermissions.systemConfigurationV;
      this._mRestrictCovid = this._rolePermissions.restrictcovid;
      this._contextSearch = this._rolePermissions.contextSearch;
      if (this._mRestrictCovid && this.localStorage.getRoleName() != 'Employee') {
        this._mCheck = true;
        this.router.navigate(["/myatom/user/my-profile"]);
      }
      this.initialize();
    });
  }

  getRolePermissions(roleId) {
    return new Promise(resolve => {
      // this.apiService.doGetRequestWithResponse(ApiConstants.getRolePermission, success => {
      this.apiService.doGetRequestWithResponse(ApiConstants.getRolePermissions + roleId, success => {
        if (success.status == 200) {
          this.localStorage.setPermissionObject(success.body);
          resolve(null);
        }
      }, error => {
        resolve(error);
      });
    });
  }

  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  initialize(): void {
    this.menuItems = [
      {
        id: 1,
        label: 'MyProfile',
        link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.userProfile.myProfile,
        icon: 'bx-home-circle',
        permission: this._rolePermissions.myProfile,
      },
      {
        id: 2,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.dashboard.baseUrl,
        permission: this._rolePermissions.dashboard,
        isUiElement: true,
      }, {
        id: 3,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.vendorDashboard.baseUrl,
        permission: this._rolePermissions.vendorDashboard,
        isUiElement: true,
      },
      {
        id: 40,
        label: 'Operations',
        icon: 'bx-customize',
        permission: this._rolePermissions.operationsV3,
        subItems: [
          {
            id: 41,
            label: 'Vendor',
            permission: this._rolePermissions.vendor,
            subItems: [
              {
                id: 42,
                label: 'Vendor Master',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.vendors + '/' + RouterConstants.operations.vendor.vendorMaster,
                permission: this._rolePermissions.vendor,
                parentId: 41
              },
              {
                id: 43,
                label: 'Vendor',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.vendors + '/' + RouterConstants.operations.vendor.vendor,
                permission: this._rolePermissions.vendor,
                parentId: 41
              },
              {
                id: 43,
                label: 'Vendor Master Info',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.vendors + '/' + RouterConstants.operations.vendor.vendorMasterInfo,
                permission: this._rolePermissions.vendor,
                parentId: 41
              },
            ]
          },
          {
            id: 44,
            label: 'Driver',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.driver,
            permission: this._rolePermissions.driver
          },
          {
            id: 45,
            label: 'Vehicle',
            permission: this._rolePermissions.vehicle,
            subItems: [
              {
                id: 46,
                label: 'Vehicle Type',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.vehicle + '/vehicle-type',
                permission: this._rolePermissions.vehicle,
                parentId: 45
              },
              {
                id: 47,
                label: 'Vehicle',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.vehicle + '/vehicle',
                permission: this._rolePermissions.vehicle,
                parentId: 45
              },
              {
                id: 48,
                label: 'Vehicle Site Mapping',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.vehicle + '/vehicle-site-mapping',
                permission: this._rolePermissions.vehicle,
                parentId: 45
              },
              {
                id: 49,
                label: 'Vehicle Driver Mapping',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.vehicle + '/vehicle-driver-mapping',
                permission: this._rolePermissions.vehicle,
                parentId: 45
              },
              {
                id: 49,
                label: 'Temporary Vehicles',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.vehicle + '/temp-vehicles',
                permission: this._rolePermissions.vehicle,
                parentId: 45
              }
            ]
          },
          {
            id: 50,
            label: 'Escort',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.escort,
            permission: this._rolePermissions.escort
          },
          {
            id: 51,
            label: 'User',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.user,
            permission: this._rolePermissions.user
          },
          {
            id: 52,
            label: 'Subscription',
            icon: 'bx-bitcoin',
            permission: this._rolePermissions.subscription,
            subItems: [
              {
                id: 53,
                label: 'Subscription',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.subscription + '/subscription',
                permission: this._rolePermissions.subscription,
                parentId: 52
              },
              {
                id: 54,
                label: 'Unsubscription',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.subscription + '/unsubscription',
                permission: this._rolePermissions.subscription,
                parentId: 52
              },
              {
                id: 55,
                label: 'Suspend Subscription',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.subscription + '/suspend-subscription',
                permission: this._rolePermissions.subscription,
                parentId: 52
              },
              {
                id: 55,
                label: 'Pending Subscription',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.subscription + '/pending-subscription',
                permission: this._rolePermissions.subscription,
                parentId: 52
              },
              {
                id: 95,
                label: 'Multisite Subscription',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.subscription + '/multisite-subscription',
                permission: this._rolePermissions.multiSitescription,
                parentId: 52
              }
            ]
          },
          {
            id: 56,
            label: 'Booking',
            permission: this._rolePermissions.booking,
            subItems: [
              {
                id: 57,
                label: 'Booking',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.booking + '/booking',
                permission: this._rolePermissions.booking,
                parentId: 56
              },
              {
                id: 58,
                label: 'Adhoc Booking',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.booking + '/adhoc-booking',
                permission: this._rolePermissions.adhocBookingv3,
                parentId: 56
              },
              {
                id: 59,
                label: 'Late Night Booking',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.booking + '/late-night-booking',
                permission: this._rolePermissions.lateNightBooking,
                parentId: 56
              },
              {
                id: 60,
                label: 'On Call Booking',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.booking + '/on-call-booking',
                permission: this._rolePermissions.specialbooking,
                parentId: 56
              }
            ]
          },
          {
            id: 61,
            label: 'Roster',
            permission: this._rolePermissions.roster,
            subItems: [
              {
                id: 62,
                label: 'Weekly Roster',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.roster + '/' + 'weekly',
                permission: this._rolePermissions.roster,
                parentId: 61
              },
              {
                id: 63,
                label: 'Alter Roster',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.roster + '/' + 'alter',
                permission: this._rolePermissions.roster,
                parentId: 61
              }
            ]
          },
          {
            id: 64,
            label: 'Role Delegation',
            permission: this._rolePermissions.roleDelegation,
            subItems: [
              {
                id: 65,
                label: 'View Role Delegation',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.roleDelegation + '/' + 'view',
                permission: this._rolePermissions.roleDelegation,
                parentId: 64
              },
              {
                id: 66,
                label: 'Add Role Delegation',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.roleDelegation + '/' + 'add',
                permission: this._rolePermissions.roleDelegation,
                parentId: 64
              }
            ]
          },
          {
            id: 67,
            label: 'Geo Code',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.geocode,
            permission: this._rolePermissions.geoCode
          },
          {
            id: 68,
            label: 'AL - Special Booking',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.operations.baseUrl + '/' + RouterConstants.operations.specialBooking,
            permission: this._rolePermissions.spclBookingAL
          },
        ]
      },
      {
        id: 66,
        icon: 'bx-collection',
        label: 'Routing',
        permission: this._rolePermissions.routingV3,
        subItems: [
          {
            id: 67,
            label: 'Adjustment Trips',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.adjustmentTrips,
            permission: this._rolePermissions.adjustmentTrips,
            parentId: 66
          },
          {
            id: 68,
            label: 'Vendor Adjustment Trips',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.vendoradjustmenttrips,
            permission: this._rolePermissions.vendorAdTrips,
            parentId: 66
          },
          {
            id: 69,
            label: 'Delete Vendor Alloc',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.deleteVendor,
            permission: this._rolePermissions.deleteVendorAllocation,
            parentId: 66
          },
          {
            id: 70,
            label: 'Manage Master Route',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.manageMaster,
            permission: this._rolePermissions.manageMasterRoute,
            parentId: 66
          },
          {
            id: 71,
            label: 'Master Routing',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.masterRouting,
            permission: this._rolePermissions.masterRouting,
            parentId: 66
          },
          {
            id: 72,
            label: 'Routing Setup',
            permission: this._rolePermissions.routingSetup,
            subItems: [
              {
                id: 73,
                label: 'Routing Setup',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.routingSetup + '/routing-setup',
                permission: this._rolePermissions.routingSetup,
                parentId: 72
              },
              {
                id: 74,
                label: 'Fixed Routing Setup',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.routingSetup + '/fixed-routing-setup',
                permission: this._rolePermissions.routingSetup,
                parentId: 72
              },
              {
                id: 75,
                label: 'Route map Setup',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.routingSetup + '/route-map-setup',
                permission: this._rolePermissions.routingSetup,
                parentId: 72
              },
              {
                id: 76,
                label: 'Special Category Setup',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.routingSetup + '/special-category-setup',
                permission: this._rolePermissions.routingSetup,
                parentId: 72
              },
              {
                id: 77,
                label: 'Navigation Setup',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.routingSetup + '/navigation-setup',
                permission: this._rolePermissions.routingSetup,
                parentId: 72
              }
            ]
          },
          {
            id: 78,
            label: 'Shift Mapping',
            permission: this._rolePermissions.shiftMapping,
            subItems: [
              {
                id: 79,
                label: 'Zone Shift Mapping',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.shiftMapping + '/zone-shift-mapping',
                permission: this._rolePermissions.shiftMapping,
                parentId: 78
              },
              {
                id: 80,
                label: 'Shift Pickup Point Mapping',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.shiftMapping + '/shift-pickup-point-mapping',
                permission: this._rolePermissions.shiftMapping,
                parentId: 78
              }
            ]
          },
          {
            id: 81,
            label: 'Trips',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.trips,
            permission: this._rolePermissions.trips,
            parentId: 66
          },
          {
            id: 82,
            label: 'Track Trips',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.trackTrips,
            permission: this._rolePermissions.trackTrips,
            parentId: 66
          },
          {
            id: 88,
            label: 'On Call Trips',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.onCallTrips,
            permission: this._rolePermissions.oncallTrips, //to view the on call trips info
            parentId: 66
          },
          {
            id: 88,
            label: 'On Call Booking',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.onCallBooking,
            permission: this._rolePermissions.onCallBooking, // on call booking view for vendor
            parentId: 66
          },
          {
            id: 88,
            label: 'Vendor Trip View',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.vendorTripView,
            permission: this._rolePermissions.vendorTrips,
            parentId: 66
          },
          {
            id: 88,
            label: 'On Call',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.onCall,
            permission: this._rolePermissions.onCall, // to stop or restart on call trips
            parentId: 66
          },
          {
            id: 92,
            label: 'Track TripsV3',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.routing.baseUrl + '/' + RouterConstants.routing.newTrackTrips,
            permission: this._rolePermissions.trackTripsV3,
            parentId: 66
          },
        ]
      },
      {
        id: 82,
        label: 'Live Tracking',
        icon: 'bx-file',
        permission: this._rolePermissions.liveTrackingV3,
        subItems: [
          {
            id: 83,
            label: 'Live Tracking',
            permission: this._rolePermissions.vehicleTracking,
            parentId: 82,
            subItems: [
              {
                id: 84,
                label: 'Overview',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.liveTracking.baseUrl + '/' + RouterConstants.liveTracking.liveTrack + '/overview',
                permission: this._rolePermissions.vehicleTracking,
                parentId: 83
              },
              {
                id: 85,
                label: 'Replay',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.liveTracking.baseUrl + '/' + RouterConstants.liveTracking.liveTrack + '/replay',
                permission: this._rolePermissions.vehicleTracking,
                parentId: 83
              },
            ]
          },
          {
            id: 86,
            label: 'Panic Stop',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.liveTracking.baseUrl + '/' + RouterConstants.liveTracking.panicStop,
            permission: this._rolePermissions.panicStop,
            parentId: 83
          },
          {
            id: 87,
            label: 'Safe Drop Action',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.liveTracking.baseUrl + '/' + RouterConstants.liveTracking.safeDropAction,
            permission: this._rolePermissions.safeDropAction,
            parentId: 83
          },
        ]
      },
      {
        id: 100,
        label: 'Reports',
        icon: 'bx-file',
        link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.reports.baseUrl,
        permission: this._rolePermissions.reportsV3
      },
      {
        id: 101,
        label: 'Billing',
        icon: 'bx-file',
        permission: this._rolePermissions.billingv3,
        subItems: [
          {
            id: 102,
            label: 'Billing Exception',
            permission: this._rolePermissions.billingException,
            parentId: 101,
            subItems: [
              {
                id: 103,
                label: 'General Billing',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.billing.baseUrl + '/' + RouterConstants.billing.billingException + '/general-billing',
                permission: this._rolePermissions.billingException,
                parentId: 102
              },
              {
                id: 104,
                label: 'OnCall Billing',
                link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.billing.baseUrl + '/' + RouterConstants.billing.billingException + '/oncall-billing',
                permission: this._rolePermissions.onCallBilException,
                parentId: 102
              },
            ]
          },
          {
            id: 105,
            label: 'Billing',
            name: 'biling-rediction', // only for billing rediction strict checking
            permission: this._rolePermissions.billinglogin,
            parentId: 102
          }
        ]
      },
      {
        id: 106,
        label: 'NeA',
        icon: 'fa fa-file',
        permission: this._rolePermissions.neaDashboard,
        subItems: [
          {
            id: 107,
            label: 'NeA Says',
            link: '/' + RouterConstants.layoutContainer + '/' + RouterConstants.dashboardAnalytics.baseUrl,
            permission: this._rolePermissions.neaDashboard,
            parentId: 106,
          },
        ]
      },
    ];
  }

  onMenuClick(event) {
    const nextEl = event.target.nextElementSibling;
    if (nextEl) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove("show");
      }
      nextEl.classList.toggle("show");
    }
    return false;
  }

  onSelectBilling() {
    if (this.billingAccess) {
      if (confirm("We are redirecting this page to the billing application. A new tab will open. No need to log in again")) {
        const token = this.localStorage.getAuthToken();
        window.open("https://transportbillinguat.neoffice.app/login?token=" + token);
      } else {
        return false;
      }
    } else {
      this.notifyService.showWarning('You are not authorised to access this application.', null);
    }
  }

  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  ngAfterViewInit() {
    this.activateMenu();
  }

  activateMenu() {
    const resetParent = (el: any) => {
      const parent = el.parentElement;
      if (parent) {
        parent.classList.remove('active');
        const parent2 = parent.parentElement;
        this._removeAllClass('mm-active');
        this._removeAllClass('mm-show');
        if (parent2) {
          parent2.classList.remove('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.remove('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('active');
                const menuelement = document.getElementById("topnav-menu-content")
                if (menuelement !== null) {
                  if (menuelement.classList.contains('show'))
                    document.getElementById("topnav-menu-content").classList.remove("show");
                }
              }
            }
          }
        }
      }
    };
    const links = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem = null;
    for (let i = 0; i < links.length; i++) {
      resetParent(links[i]);
    }
    for (let i = 0; i < links.length; i++) {
      if (location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i];
        break;
      }
    }
    // if (matchingMenuItem) {
    //   const parent = matchingMenuItem.parentElement;
    //   if (parent) {
    //     parent.classList.add('active');
    //     const parent2 = parent.parentElement;
    //     if (parent2) {
    //       parent2.classList.add('active');
    //       const parent3 = parent2.parentElement;
    //       if (parent3) {
    //         parent3.classList.add('active');
    //         const parent4 = parent3.parentElement;
    //         if (parent4) {
    //           parent4.classList.add('active');
    //           const parent5 = parent4.parentElement;
    //           if (parent5) {
    //             parent5.classList.add('active');
    //             const parent6 = parent5.parentElement;
    //             if (parent6) {
    //               parent6.classList.add('active');
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  myProfile() {
    this.router.navigate(['/' + RouterConstants.layoutContainer + '/' + RouterConstants.userProfile.baseUrl + '/' + RouterConstants.userProfile.myProfile]);
  }

  changePassword() {
    this.router.navigate(['/' + RouterConstants.layoutContainer + '/' + RouterConstants.userProfile.baseUrl + '/' + RouterConstants.userProfile.changePassword]);
  }

  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.userUrl);
  }

  getProfilePhoto() {
    this.userId = this.localStorage.getUserId();
    if (this.userId) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getprofilePhoto + this.userId + '&driverId=', success => {
        if (success.status === 200) {
          this.imgData = success.body;
          if (this.imgData) {
            this.userUrl = this.imgData.url;
          } else {
            this.userUrl = '';
          }
        } else {
          this.imgData = '';
          this.userUrl = '';
        }
      }, error => {
        console.log(error)
      });
    }
  }

  logout(): void {
    const dialogRef = this.dialog.open(ConfirmationAlertComponent, {
      width: '324px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        this.localStorage.deleteAll();
        this.websocketService.disconnect();
        this.router.navigate(['/' + RouterConstants.auth]);
      }
    });
  }

  clearTimer() {
    clearTimeout(this.userActivity);
    clearTimeout(this.routingActivity);
  }

  @HostListener('window:mousemove') refreshUserState() {
    if (this.localStorage.hasAuthToken()) {
      this.resetUserTimer();
      this.resetRoutingTimer();
    }
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.subs) this.subs.unsubscribe();
    if (this.atomSession) this.atomSession.unsubscribe();
    this.clearTimer();
  }

  onClickSetting() {
    this.router.navigate(['/' + RouterConstants.layoutContainer + '/' + RouterConstants.adminConsole.baseUrl]);
  }

  onClickConfiguration() {
    this.router.navigate(['/' + RouterConstants.layoutContainer + '/' + RouterConstants.configurations.baseUrl]);
  }

  clearNotification() {
    let userId = this.localStorage.getUserId();
    if (userId) {
      this.apiService.doGetRequestWithResponse(ApiConstants.markNotifications, success => {
        if (success.status == 200) {
          // this.notifyService.showInfo(success.body.message, null);
          this.getLatestNotifications();
          this.getNotificationCount();
        } else {
          this.notifyService.showInfo("No new notifications", null);
        }
      }, error => {
        console.log(error);
      })
    } else {
      this.notifyService.showInfo("Fail to clear notifications", null);
    }
  }

  searchSubmit(event) {
    this.contextSearch = '';
    this.contextSearch = event.option.value;
    this.openDialogContextLayout(this.filterValue);
  }

  searchSubmitVehicle(event) {
    this.contextSearch = '';
    this.contextSearch = event.option.value;
    this.openDialogContextLayout(this.filterValue);
  }

  onSearchKey(searchKey) {
    this._mData = this.tempData.filter(data => data.name.toLowerCase().includes(searchKey.toLowerCase()));
  }

  openDialogContextLayout(filter) {
    let reqData = {
      empId: this.contextSearch,
      filter: filter
    };
    let dialogRef = this.dialog.open(ContextSearchComponent, {
      data: reqData,
      disableClose: true,
      position: { top: '60px', right: '10px' },
      width: 'fit-content'
    });
  }


  filterSelected(value) {
    this.filterVehicle = false;
    if (this.filterValue == 'Employee') {
      this.filterFlag = false;
      this.employeeFlag = true;
      this.vehicleFlag = false;
      this.driverFlag = false;
    }
    if (this.filterValue == 'Vehicle') {
      this.filterFlag = false;
      this.employeeFlag = false;
      this.vendorMasterFlag = true;
      this.vehicleFlag = true;
      this.driverFlag = false;
      if (this.vendorFlag) {
        this.requestServerForVehicleList()
      }
    }
    if (this.filterValue == 'Driver') {
      this.filterFlag = false;
      this.employeeFlag = false;
      this.vehicleFlag = false;
      this.driverFlag = true;
      this.driverListsByAdmin('');
    }

  }

  driverListsByAdmin(searchkey: string) {
    // this.ngxLoader.start();
    this._mData = [];
    this.tempData = [];
    if (!this.vendorFlag) {
      this.apiService.doGetRequestWithResponse(ApiConstants.getDriverListsByAdmin + '', (success) => {
        this.ngxLoader.stop();
        if (success.status == 200) {
          this.ngxLoader.stop();
          this._mData = success.body;
          this.tempData = success.body;
        } else {
          this.ngxLoader.stop();
          this._mData = [];
          this.tempData = [];
          this.notifyService.showInfo("No data found", null);
        }
      },
        error => {
          this.ngxLoader.stop();
          console.log(error);
        }
      );
    }
    else {
      this.apiService.doGetRequestWithResponse(ApiConstants.getDriversByVendorMasterId + this.mVendorMasterId, (success) => {
        if (success.status == 200) {
          this.ngxLoader.stop();
          this._mData = success.body;
          this.tempData = success.body;
        } else if (success.status == 204) {
          this.ngxLoader.stop();
          this.notifyService.showInfo("No data found", null);
          this._mData = [];
          this.tempData = [];
        }
      }, (error) => {
        this.ngxLoader.stop();
        console.log(error);
      });
    }
  }

  searchSubmitDriver(event) {
    this.contextSearch = '';
    this.contextSearch = event.option.value;
    this.openDialogContextLayout(this.filterValue);
  }

  getVendorByUserId(currentUserId) {
    this.apiService.doGetRequestWithResponse(ApiConstants.getVendorByUserId + currentUserId, (success) => {
      if (success.body != null) {
        this.mVendorMasterId = success.body.vendorMaster.id;
      }
    }, (error) => {
    })
  }

  requestServerForVehicleList() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getvehiclelistbyvendormasterid + this.mVendorMasterId, (success) => {
      if (success.status == 200) {
        this.vehicleData = success.body;
        this.tempVehicleData = success.body;
      } else {
        this.vehicleData = [];
        this.tempVehicleData = [];

      }
    }, error => {

    })
  }

  onSearchVehicle(searchKey) {
    this.vehicleData = this.tempVehicleData.filter(data => data.vehicleNo.toLowerCase().includes(searchKey.toLowerCase()));
  }



}
