import { Component, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/app/core/services/api.service';
import { ServiceLocalStorage } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AddVendorAdjustmentTripsComponent } from './add-vendor-adjustment-trips/add-vendor-adjustment-trips.component';
import { ApiConstants } from 'src/app/core/constants/api-path.constants';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-adjustment-trips-vendor',
  templateUrl: './adjustment-trips-vendor.component.html',
  styleUrls: ['./adjustment-trips-vendor.component.scss']
})
export class AdjustmentTripsVendorComponent {
  _mColumns: any[] = [];
  _mData: any[] = [];
  firstStep: boolean = false;
  secondStep: boolean = false;
  _mCurrentUserData: any;
  dataSource = new MatTableDataSource<any>(this._mData);
  displayedColumns = ['date', 'tripCode', 'siteName', 'vendorName', 'employeeId', 'reason', 'logType', 'nearestShiftTime', 'approximateKm', 'escortRequired', 'vehicleType', 'vehicleNo', 'driverName', 'status'];
  statusData = [{ status: 'ALL' }, { status: 'SENT_FOR_ADMIN_APPROVAL' }, { status: 'Approved' }, { status: 'Rejected' }]
  cities: any;
  _mSitesData: any;
  public _mFormGroup: FormGroup;
  public mLocation = new FormControl("", Validators.required);
  public mSite = new FormControl("", Validators.required);
  public mFromDate = new FormControl('');
  public mToDate = new FormControl("", Validators.required);
  public mstatus = new FormControl("", Validators.required);
  minDate: any;
  maxDate: any;
  tableFlag: boolean;
  page: number = 0;
  size: number = 20;
  _mCurrentNumber: number = 0;
  _mTotalElements: number = 0;
  _mNumberOfElements: number = 0;
  _mTotalPage: number = 1;
  itemsPerPage = 0;

  constructor(
    private readonly apiService: ApiService,
    private notifyService: NotificationService,
    public readonly serviceLocalStorage: ServiceLocalStorage,
    private ngxLoader: NgxUiLoaderService,
    private fb: FormBuilder,
    public readonly dialog: MatDialog,
  ) {
    this._mFormGroup = this.fb.group({
      location: this.mLocation,
      site: this.mSite,
      fromDate : this.mFromDate,
      toDate : this.mToDate,
      status: this.mstatus
    });
  }

  ngOnInit(): void {
    this._mCurrentUserData = this.serviceLocalStorage.getUserObject();
    this.loadCities();
  }

  openDialog(userEdit): void {
    let data = null;
    if (this._mCurrentUserData.userInfoDetails.role.id == 3) {
      data = {
        employeeAccessFlag: true,
        userData: this._mCurrentUserData
      };
    } else if (this._mCurrentUserData.userInfoDetails.role.id == 4) {
      data = {
        employeeAccessFlag: true,
        userData: null
      };
    } else if (
      (this._mCurrentUserData.userInfoDetails.role.id == 1 ||
        this._mCurrentUserData.userInfoDetails.role.id == 2) &&
      userEdit != null
    ) {
      data = {
        employeeAccessFlag: false,
        userData: userEdit
      };
    } else {
      data = null;
    }
    let dialogRef = this.dialog.open(AddVendorAdjustmentTripsComponent, {
      disableClose: true,
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ngOnInit();
      }
    });
  }

  loadCities() {
    this.apiService.doGetRequestWithResponse(ApiConstants.getserchlocationlist,
      success => {
        if (success.status == 200) {
          this.cities = success.body;
        } else {
          this.cities = [];
        }
      },
      error => {
        this.notifyService.showError(error, null);
      }
    );
  }

  dateChanged(data) {
    this.mToDate.setValue("");
    this.minDate = data.value;
  }
  getSitesByCityId() {
    this.mSite.setValue('');
    this.apiService.doGetRequestWithResponse(ApiConstants.getserchsitelist +
    this.mLocation.value,
      success => {
        if (success.status == 200) {
          this._mSitesData = success.body;
        } else {
          this._mSitesData = [];
          this.notifyService.showInfo("No Sites Found!", null);
        }
      },
      error => {
      }
    );
  }

  onSubmit() {
    if (this._mFormGroup.valid) {
      this.ngxLoader.start();
      this.apiService.doGetRequestWithResponse(ApiConstants.getAdjustmentTrip + this.mSite.value + '&fromDate=' + this.formatDate(this.mFromDate.value) + '&toDate=' + this.formatDate(this.mToDate.value) +
        '&status=' + this.mstatus.value + '&page=' + (this.page - 1) + '&size=' + this.size, success => {
          if (success.status == 200) {
            this.ngxLoader.stop();
            this.tableFlag = true;
            if (success.body.data.length == 0) {
              this.notifyService.showInfo("No data found", null);
              this._mData = [];
              this._mCurrentNumber = 0;
              this._mTotalElements = 0;
              this._mTotalPage = 0;
              this._mNumberOfElements = 0;
              this.size = 0;
            }
            else {
              // this.mData = success.body;
              let locarr: any[] = [];
              success.body.data.forEach(element => {
                locarr.push({
                  "id": element.id, "date": element.date, "tripCode": element.tripCode, "siteName": element.siteName, "vendorName": element.vendorName,
                  "employeeId": element.employeeId, "reason": element.reason, "logType": element.logType, "nearestShiftTime": element.nearestShiftTime, "approximateKm": element.approximateKm, "escortRequired": element.escortRequired, "vehicleType": element.vehicleType, "vehicleNo": element.vehicleNo, "driverName": element.driverName, "status": element.status,
                });
              });
              this._mData = locarr;
              this.dataSource.data = locarr;
              this._mCurrentNumber = success.body.pageNo;
              this._mTotalPage = success.body.totalPages;
              this.size = success.body.size;
              this._mTotalElements = success.body.totalElements;
              this._mNumberOfElements = success.body.noOfElements;
            }
          }
          else {
            this.ngxLoader.stop();
            this._mCurrentNumber = 0;
            this._mTotalElements = 0;
            this._mTotalPage = 0;
            this._mNumberOfElements = 0;
            this.size = 0;
            this._mData = [];
            this.notifyService.showInfo("No data found", null);
          }
        },
        (error) => {
        });
    }
  }

formatDate(d): any {
  let m = d.getMonth() + 1;
  let dt = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  let mm = m < 10 ? "0" + m : m;
  return d.getFullYear() + "-" + mm + "-" + dt;
}

onNewPageRequest($event) {
  if($event !== null || $event !== undefined)
  {
  this.page = $event;
  this.onSubmit();
  }
}
}

