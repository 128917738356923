import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { ServiceLocalStorage } from './local-storage.service';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { ApiService } from './api.service';
import { ApiConstants } from '../constants/api-path.constants';
import { PanicActionNotificationComponent } from '../../layouts/panic-action-notification/panic-action-notification.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from "src/environments/environment";
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private stompClient: any;
  _mPanicActionFlag: any = true;
  stompClientLiveTracking: any;
  constructor(private readonly localStorage: ServiceLocalStorage,
    private readonly apiService: ApiService,
    public dialog: MatDialog,
  ) { }

  _connect() {
    // Create SockJS instance for WebSocket connection
    const ws = new SockJS(environment.baseUrl + ApiConstants.websocketPanicNotification,null, { transports: ['websocket'] });
    this.stompClient = Stomp.over(ws);  // Create STOMP client over SockJS
   
    if (this.stompClient) {
      this.stompClient.connect({}, (frame) => {
        console.log('Connected to server:', frame); // Should display frame info
        this.stompClient.subscribe(ApiConstants.websocketPanicNotification, (message: any) => {
          let jsonObject: any = {};
          jsonObject = JSON.parse(message.body);
          this.showSingleRunningTrip(jsonObject.tripId, jsonObject);
          // this.onMessageReceived(message.body);
        });
      }, this.errorCallBack);
    }
  }

  // liveTrackingWebsocket(reqData, getLiveData)
  // {
  //   if(!this.stompClientLiveTracking)
  //   {
  //   const ws = new SockJS(environment.liveTrackingWebsocketBaseURL + ApiConstants.websocketLivetracking, null, { transports: ['websocket'] });
  //   this.stompClientLiveTracking = Stomp.over(ws);  // Create STOMP client over SockJS
  //   if (this.stompClientLiveTracking) {
  //     this.stompClientLiveTracking.connect({}, (frame) => {
  //       this.stompClientLiveTracking.subscribe(ApiConstants.websocketLivetrackingSubscribe, (message: any) => {
  //         let jsonObject: any = {};
  //         jsonObject = JSON.parse(message.body);
  //         return jsonObject;
  //       });
  //     }, this.errorCallBack);
  //   }
  //   else
  //   {
  //     this.notifyService.showError("Error in connecting with Websocket", null);
  //   }

  // }
  //   setTimeout(() => {
  //       this.subsWebsocket.push(interval(3000).subscribe(val => {
  //         this.stompClientLiveTracking.send(ApiConstants.websocketLivetrackingSendMessage, {}, JSON.stringify(reqData));
  //       }))
  //   }, 1000);

  //   // this.disconnect();
   
  // }

   showSingleRunningTrip(tripId, data) {
      let reqData = { tripId: tripId };
      let userObject: any = this.localStorage.getUserObject();
      this.apiService.doPostRequestWithResponse(ApiConstants.showSingleRunningTrip + reqData.tripId, '', success => {
        setTimeout(() => {
        if (this._mPanicActionFlag) {
          this._mPanicActionFlag = false;
          const audio = new Audio("assets/mp3/notification_alarm.mp3");
          audio.play();
          setTimeout(() => {
            audio.pause();
          }, 10000);
          let reqData = {
            tripData: success.body,
            panicActionData: data,
            currentUserId: userObject.id,
            mapType: userObject.userInfoDetails.site.location.company.mapType
          };
          let dialogRef = this.dialog.open(PanicActionNotificationComponent, {
            width: "1250px",
            data: reqData,
            disableClose: true,
            panelClass: 'custom-dialog-container',
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this._mPanicActionFlag = true;
              audio.pause();
            }
          });
        }
      }, 100);
      }, error => {
        console.log(error)
      });
    }

  onMessageReceived(message: any) {
    const messageBody = JSON.parse(message.body);
    console.log("Message Received from Server: ", messageBody);
  }

  errorCallBack(error: any) {
    console.error("Error in WebSocket connection:", error);
    setTimeout(() => {
      // this._connect();
    }, 5000);
  }

  disconnect() {
    if (this.localStorage.authToken == undefined) {
      this.stompClient.disconnect(() => {
        console.log("Disconnected from WebSocket");
      });
    }
  }

  ngOnDestroy()
  {
    if ( this.stompClientLiveTracking) {
      this.stompClientLiveTracking.disconnect(() => {
        console.log("Disconnected from WebSocket");
      });
    }
   }

}
